import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { HCRProvider } from "contexts/hcr.context";
import * as Sentry from "@sentry/react";

const App = React.lazy(() => import(/* webpackChunkName: "App" */ "./App"));

const Main = () => {
  if (["prod"].includes(process.env.REACT_APP_ENV)) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DNS,
      environment: "production",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false
        })
      ],
      tracePropagationTargets: "admin.insurancesamadhan.com",
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0
    });
  }

  return (
    <Provider store={configureStore}>
      <Suspense fallback={<div className="loading" />}>
        <BrowserRouter>
          <HCRProvider>
            <App />
          </HCRProvider>
        </BrowserRouter>
      </Suspense>
    </Provider>
  );
};

ReactDOM.render(<Main />, document.getElementById("root"));
reportWebVitals();
