import { all, call, put, takeLatest } from "redux-saga/effects";
import { GET_INSA_ENGINE_DATA_BY_SEARCH_FAILED, GET_INSA_ENGINE_DATA_BY_SEARCH_START, GET_INSA_ENGINE_DATA_BY_SEARCH_SUCCESS, GET_INSA_ENGINE_DATA_FAILED, GET_INSA_ENGINE_DATA_START, GET_INSA_ENGINE_DATA_SUCCESS } from "./Action";
import { request } from "services/requests.services";
import { servicePath } from "constants/defaultValues";

function* getInsaEngineDataAsync(action) {
    try {
        const data = yield request("GET", `${servicePath}/insa-ai/business-data?currentPage=${action.state?.currentPage}`);
        yield put({
            type: GET_INSA_ENGINE_DATA_SUCCESS,
            data: data?.data
        })
    } catch (error) {
        console.log(error);
        yield put({ type: GET_INSA_ENGINE_DATA_FAILED })
    }
}

function* getInsaEngineDataBySearchAsync(action) {
    try {
        const data = yield request("GET", `${servicePath}/insa-ai/search-business-data?keyword=${action.state?.search}&currentPage=${action.state?.currentPage}`);
        yield put({
            type: GET_INSA_ENGINE_DATA_BY_SEARCH_SUCCESS,
            data: data?.data
        })
    } catch (error) {
        console.log(error);
        yield put({ type: GET_INSA_ENGINE_DATA_BY_SEARCH_FAILED })
    }
}

function* getBusinessData() {
    return yield takeLatest(GET_INSA_ENGINE_DATA_START, getInsaEngineDataAsync);
}

function* getInsaEngineDataBySearch() {
    return yield takeLatest(GET_INSA_ENGINE_DATA_BY_SEARCH_START, getInsaEngineDataBySearchAsync)
}

export default function* InsaEngineSaga() {
    yield all([call(getBusinessData), call(getInsaEngineDataBySearch)]);
}